import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import {
	Amex,
	Facebook,
	FooterHeadingHand,
	Instagram,
	Linkedin,
	LogoFooter,
	MasterCard,
	Visa,
} from "../../assets";

export default function Footer({ links, locale }) {
	const { t } = useTranslation();
	const lang = locale || "fr";

	return (
		<div className="footer">
			<h2>
				{t("footer.title.1")}
				<br /> {t("footer.title.2")} <br /> {t("footer.title.3")}{" "}
				<FooterHeadingHand />
			</h2>
			<ul className="footer_links_page">
				{links.map((l, i) => (
					<a key={i} href={l.href}>
						{l.label}
					</a>
				))}
			</ul>
			<ul className="footer_company_info">
				<li>Bene Bono® {new Date().getFullYear()}</li>
				<a
					href={
						lang !== "es" &&
						"https://static.horsnormes.co/static/legal/20220224-BeneBono-Politique-De-Confidentialite.pdf"
					}
					target="_blank"
					rel="noreferrer"
				>
					{t("footer.privacynotice")}
				</a>
				<a
					href={
						lang !== "es"
							? "https://static.horsnormes.co/static/legal/20241001-CGV-BeneBono.pdf"
							: "https://static.horsnormes.co/static/legal/CGV-spain.pdf"
					}
					target="_blank"
					rel="noreferrer"
				>
					{t("footer.conditionsofuse")}
				</a>
				<a
					href={
						"https://static.horsnormes.co/static/legal/20241001-BeneBono-MentionsLegales.pdf"
					}
					target="_blank"
					rel="noreferrer"
				>
					{t("footer.legal_mentions")}
				</a>
				<li>{t("footer.cookies")}</li>
			</ul>
			<LogoFooter />
			<div className="footer_socials">
				<a
					target="_blank"
					rel="noreferrer"
					href={
						lang === "es"
							? "https://www.instagram.com/benebono.es/"
							: "https://www.instagram.com/benebono.fr/"
					}
				>
					<Instagram />
				</a>
				<a
					target="_blank"
					rel="noreferrer"
					href={
						lang === "es"
							? "https://www.facebook.com/benebono.es/"
							: "https://www.facebook.com/benebono.fr/"
					}
				>
					<Facebook />
				</a>
				<a
					target="_blank"
					rel="noreferrer"
					href={
						lang === "es"
							? "https://www.linkedin.com/company/benebono-fr"
							: "https://www.linkedin.com/company/benebono-fr"
					}
				>
					<Linkedin />
				</a>
			</div>
			<div className="footer_benebono_info">
				<div>
					<p>{t("footer.email")}</p>
					<p>{t("footer.address")}</p>
				</div>
				<div>
					<p>{t("footer.certification")}</p>
					<p>{t("footer.certification.code")}</p>
				</div>
			</div>
		</div>
	);
}
