import { useEffect } from "react";

const DIXA_KEY = process.env.NEXT_PUBLIC_DIXA_API_KEY;

export default function DixaChatOpener() {
  console.log({ DIXA_KEY });
  // useEffect(() => {
  //   if (typeof window !== "undefined" && window._dixa_) {
  //     window?._dixa_?.invoke("init", {
  //       messengerToken: DIXA_KEY,
  //       showWidgetCloseButton: true,
  //     });
  //   }
  // }, []);
}
