import {
	PlasmicComponent,
	PlasmicRootProvider,
} from "@plasmicapp/loader-nextjs";
import { PLASMIC } from "../../plasmic-init";

const PlasmicWrapper = ({
	plasmicData,
	queryCache,
	locale,
	pageParams,
	componentProps,
	query,
}) => {
	const compMeta = plasmicData.entryCompMetas[0];

	return (
		<PlasmicRootProvider
			loader={PLASMIC}
			prefetchedData={plasmicData}
			prefetchedQueryData={queryCache}
			pageParams={pageParams || compMeta.params} ////using params seems the best option, need to check if removing compMeta.params doesn't break things on other pages
			pageQuery={query}
			globalVariants={[
				{
					name: "Locale",
					value: locale,
				},
			]}
		>
			<PlasmicComponent
				component={compMeta.displayName}
				componentProps={componentProps}
			/>
		</PlasmicRootProvider>
	);
};

export default PlasmicWrapper;
