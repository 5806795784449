/* eslint-disable @next/next/no-img-element */
import {
	DataProvider,
	PlasmicCanvasContext,
	usePlasmicQueryData,
	useSelector,
} from "@plasmicapp/loader-nextjs";
import React, { useContext } from "react";
import { useRouter } from "next/router";

const categoryMapping = {
	Apéritifs: "appetizers",
	"Sauces, Condiments & Épices": "sauces_condiments_and_spices",
	Bières: "beers",
	"Cuisines du monde": "world_cuisines",
	"Chocolats & Confiseries": "chocolates_and_sweets",
	"Biscuits & Gâteaux": "biscuits_and_cakes",
	"Fruits secs": "dried_fruits",
	"Conserves & Bocaux": "preserves_and_jars",
	"Pâtes, Riz & Céréales": "pasta_rice_and_cereals",
	"Plats cuisinés & Soupes": "ready_meals_and_soups",
	"Desserts & Compotes": "desserts_and_compotes",
	"Café, Thé & Boissons chaudes": "coffee_tea_and_hot_drinks",
	"Crèmerie & Fromages": "dairy_and_cheeses",
	"Pâtes à tartiner, Confitures & Miels": "spreads_jams_and_honey",
	"Laits & Boissons végétales": "milks_and_plant_drinks",
};

function getCategory(slug, inEditor) {
	if (slug === "home" || slug === "catalog") {
		return slug;
	}
	if (inEditor) {
		return Object.values(categoryMapping)[0];
	}
	if (slug && Object.values(categoryMapping).includes(slug)) {
		return slug;
	}

	//should we have a default ?
	return null;
}

export default function Catalog(props) {
	const { children, className } = props;
	const plasmicCategory = props.category;

	const inEditor = useContext(PlasmicCanvasContext);
	const category = getCategory(plasmicCategory, inEditor);

	const { data, error } = usePlasmicQueryData(
		["categories", category],
		async () => {
			const apiUrl = `${process.env.NEXT_PUBLIC_FAIT_MAISON_URL}/categories/products`;
			const resp = await fetch(apiUrl, { cache: "no-store" });

			if (!resp.ok) {
				throw new Error("Échec de l'appel API");
			}
			const fetchedData = await resp.json();

			if (fetchedData && category === "home") {
				const firstProductsArray = fetchedData.data.map((item) => {
					if (Array.isArray(item.products) && item.products.length > 0) {
						return item.products[0];
					}
					return null;
				});
				return {
					products: firstProductsArray,
					categoryName: "home",
					allCategoryName: [],
				};
			}

			if (fetchedData && category) {
				const dataParse = fetchedData.data.map((cat) => ({
					category:
						categoryMapping[cat.category.category] || cat.category.category,
					categoryName: cat.category.category,
					image: cat.category.image,
					products: cat.products,
				}));

				const allCategoryName = dataParse.map((cat) => ({
					categoryName: cat.categoryName,
					imgCategory: cat.image,
					categoryPath: `/catalog/${cat.category}`,
				}));

				allCategoryName.sort((a, b) =>
					a.categoryName.localeCompare(b.categoryName),
				);

				const matchedCategory = dataParse.find(
					(item) => item.category === category,
				);

				while (matchedCategory?.products.length < 50) {
					const newProduct = JSON.parse(
						JSON.stringify(matchedCategory.products[0]),
					);
					newProduct.title = (matchedCategory?.products.length + 1).toString();
					matchedCategory.products.push(newProduct);
				}

				return {
					products: matchedCategory?.products || [],
					categoryName: matchedCategory?.categoryName || "",
					allCategoryName,
				};
			}

			return { products: [], categoryName: "", allCategoryName: [] };
		},
	);

	if (error) {
		console.error("Erreur de récupération :", error);
		return null;
	}

	if (!data || data.products.length === 0) {
		return null;
	}

	return (
		<DataProvider
			name="catalog"
			data={{
				products: data.products,
				categoryName: data.categoryName,
				allCategoryName: data.allCategoryName,
			}}
			className={className}
		>
			<div className={className}>{children}</div>
		</DataProvider>
	);
}
