import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslation } from "next-i18next";
import { trackZipcodeEntered } from "../../utils/helpers/tracking";
import { useRouter } from "next/router";

export default function ZipCode() {
	const [zipCode, setZipCode] = useState("");
	const [isEmpty, setIsEmpty] = useState(false);
	const zipCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
	const [isNotValid, setIsNotValid] = useState(false);
	const [lang, setLang] = useState("");
	const errorRef = useRef(null);
	const inputRef = useRef(null);
	const { t } = useTranslation();
	const router = useRouter();

	useEffect(() => {
		const topLevelDomain = window.location.hostname.split(".").pop();
		setLang(topLevelDomain === "es" ? "es" : "fr");
	}, []);

	function checkZipCode(e) {
		e.preventDefault();
		axios
			.get(
				`${
					process.env.NEXT_PUBLIC_FAIT_MAISON_URL
				}/delivery/zipcheck/${lang}/${zipCode.trim()}`,
			)
			.then((res) => {
				if (res.data) {
					trackZipcodeEntered(zipCode, "home", lang, true);
					router.push(`/baskets?zipcode=${zipCode}`);
					localStorage.setItem("zipcode", JSON.stringify(zipCode));
					setIsEmpty(false);
				} else {
					trackZipcodeEntered(zipCode, "home", lang, false);
					setIsNotValid(true);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function limitDigits(input, maxDigits) {
		if (input?.value?.length > maxDigits) {
			input.value = input.value.slice(0, maxDigits);
		}
	}

	function handleChange(e) {
		limitDigits(e.target.value, 5);
		setZipCode(e.target.value);
	}

	return (
		<>
			<form
				onSubmit={(e) => {
					if (zipCode.match(zipCodeRegex)) {
						checkZipCode(e);
					}
					if (!zipCode) {
						setIsEmpty(true);
						inputRef?.current?.focus();
					}
				}}
				className="zipcode"
			>
				<input
					type="text"
					onChange={handleChange}
					placeholder={t("input.zipcode.placeholder")}
					ref={inputRef}
					maxLength={5}
					inputMode="numeric"
					pattern="[0-9]{5}"
				/>
				<input
					type="submit"
					className="button_desktop"
					value={t("button.baskets")}
				/>
			</form>
			{isEmpty ? (
				<p
					style={{
						color: "red",
						margin: 0,
						marginTop: "5px",
						paddingLeft: "10px",
					}}
				>
					{t("zipcode.error.format")}
				</p>
			) : null}
			{isNotValid ? (
				<p onClick={() => {}} ref={errorRef} className="error_zipcode">
					{t("error.message.delivery")}
					<a
						className="signup_zipcode"
						href={
							lang === "es"
								? `https://tally.so/r/mVGLxj?cp=${zipCode}`
								: `https://tally.so/r/wQOVYm?cp=${zipCode}`
						}
					>
						{t("subscription.wait.list.link")}
					</a>
					{t("susbcription.wait.list")}
				</p>
			) : null}
		</>
	);
}
